import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// import NotFound from "views/shared-component/404";
import Image from "helpers/Image";

// Layouts
import AuthLayouts from "layouts/AuthLayouts";
import AppLayout from "layouts/CommonLayout";
import { useProfileDetails } from "store/hooks/profile/profile";
import { useContextHelper } from "helpers/ContextHelper";
import globalRouter from "globalRouter.ts";



// Report & Performance Routes
const Dashboard = lazy(() => import("./views/app/dashboard/index"));

// System Configuration Routes
const UserManagement = lazy(() => import("./views/app/userAndDepartment/user"));
const DepartmentManagement = lazy(() => import("./views/app/userAndDepartment/department"));
const Role = lazy(() => import("./views/app/userAndDepartment/role/role"))
const NotificationManagement = lazy(() => import("./views/app/notificationManagement/index"));
const  Holiday = lazy(()=>import("./views/app/userAndDepartment/holiday"))

const MachineList = lazy(() => import("./views/app/machineList/machine"));
const SubMachineList = lazy(() => import("./views/app/machineList/subMachine"));
const SubArchieve = lazy(() => import("./views/app/machineList/Archieve"));

const MachineInspection = lazy(() => import("./views/app/MachineInspection/machineList/index"))
const MachineSystem = lazy(() => import("./views/app/MachineInspection/machineSystem/index"))
const Subtable = lazy(()=>import("./views/app/MachineInspection/machineList/components/qrList"))


const Chart = lazy(() => import("../src/views/app/chart/chart"));
const Scorecard = lazy(() => import("../src/views/app/scorecard/scorecard"))
const IndividualCard = lazy(() => import("../src/views/app/scorecard/individual"))
const Activitylog = lazy(() => import("./views/app/activityLogs/activitylog"))
const Profile = lazy(() => import("../src/views/app/profile/profile"))

const QRCode = lazy(() => import("./views/app/QRcodescanner/machineList"));

const Register = lazy(() => import("../src/views/auth/register"))
const Login = lazy(() => import("../src/views/auth/login"))
const ForgotPassword = lazy(() => import("./views/auth/forgotPassword/index"));
const SetPassword = lazy(() => import("./views/auth/forgotPassword/setPassword"));

function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  const id = localStorage.getItem('userId');
  const { setProfile } = useContextHelper();
  const { data: profileData, refetch } = useProfileDetails(id);

  useEffect(() => {
    if (profileData) {
      setProfile(profileData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  useEffect(() => {
    if (id && id !== null) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Suspense fallback={<div className="loader-img"><Image name="mainLoader2.gif" /></div>}>
      <Routes>
        <Route element={<AuthLayouts />}>
          <Route exact path="/" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />

        </Route>
      </Routes>
      <Routes>
        <Route element={<AppLayout initialRefetch={refetch} />}>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/department-management" element={<DepartmentManagement />} />
          <Route path="/role" element={<Role />} />
          <Route path="/notification-management" element={<NotificationManagement />} />
          <Route path="/machine-list" element={<MachineList />} />
          <Route path="/machine-list/sub-machine" element={<SubMachineList />} />
          <Route path="/machine-list/archieve" element={<SubArchieve />} />
          <Route path="/machine-list/sub-table" element={<Subtable />} />
          <Route path="/machine-inspection" element={<MachineInspection />} />
          <Route path="/machine-inspection/machine-system" element={<MachineSystem />} />
          <Route path="/chart" element={<Chart />} />
          <Route path="/scorecard" element={<Scorecard />} />
          <Route path="/individualcard" element={<IndividualCard />} />
          <Route path="/activitylog" element={<Activitylog />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/machine-inspection/inspection-list" element={<QRCode />} />
          <Route path = "/holiday" element={<Holiday/>}/>
            </Route>
      </Routes>

    </Suspense>
  );
}

export default App;

